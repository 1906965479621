export const MCFX_DOMAIN = `${process.env.POI_APP_LEGACY_DOMAIN}`;
export const API_KEY = 'VItRka3J1q3HIfbjj2La8wzU';
export const LOOKER_EMBED_HOST = 'https://looker.webfx.com';

export const DEFAULT_ANALYTICSFX_LABELS = [
  'Internet Marketing',
  'Conversion Rate Optimization',
  'Email Marketing',
  'Content Marketing',
  'Social Media',
  'Local SEO',
  'LeadNurtureFX',
];

export const SHORT_DATE = 'MM/DD/YYYY hh:mma';
export const SHORT_DATETIME = 'MM/DD/YYYY hh:mma';

// AdtechFX Todolist ID
export const INTEGRATION_TODOLIST_ID = 31951322; // IntegrationFX Todolist
export const INTEGRATIONS_TODOITEM_USER_ID = 11332601; // FX Projects (reporting@webfx.com)
export const ADTECH_PLUS_TODOITEM_USER_ID = 12091651; // Karley Ice-Kalinay (karley@webfx.com)
export const ADTECH_TODOITEM_COMMENT_USER_ID = 12550840; // Alicia Sztobryn (asztobryn@webfx.com)
export const MCFX_SOLUTIONS_TODOITEM_USER_ID = 11738272; // Kristina
export const ADTECH_ABMFX_CTA_ID = 333369473; // /todo-lists/333369473
export const INTEGRATION_ANCIENT_LITE_TODOLIST_ID = 32024581; // Ancient Lite Todolist
export const INTEGRATION_ANCIENT_LITE_TODOITEM_USER_ID = 1226156612; // Emily Tay

export const OST_TEAM_TODOLIST_ID = 32024581;
export const OST_TEAM_ASSIGNEE_ID = 1226156612; // Emily Tay (etay@webfx.com)

export const DATATECH_QUEUES_TODOLIST_ID = 333438346; // WPFX: Priorities: DataTech Solutions Queues
export const DATATECH_QUEUES_COMMENT_USER_ID = 12715606; // Melissa Hostetter

export const ATFX_TODOLIST_ID = 333369473;

//CTXPro
export const managementLevels = [
  'Owner',
  'Partner',
  'C-level',
  'VP',
  'Director',
  'Manager',
  'Senior',
  'Training',
  'Entry',
  'Unpaid',
];

export const jobFields = [
  'Customer Service',
  'Design',
  'Education',
  'Engineering',
  'Finance',
  'Health',
  'Human Resources',
  'Legal',
  'Marketing',
  'Media',
  'Operations',
  'Public Relations',
  'Real Estate',
  'Sales',
  'Trades',
];
