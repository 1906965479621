import { useAuth, useActiveSite } from '@webfx/web-hooks';
import { useStoreState } from 'easy-peasy';

function useMenuState() {
  const { siteId } = useActiveSite();
  const { url, route, pathname } = useStoreState((state) => state.router);
  const isAdmin = useAuth().isAdmin;

  return {
    activeId: siteId,
    pathname,
    url,
    route,
    isAdmin,
    siteId,
  };
}

export default useMenuState;
